:root {
  /* font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400; */

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}



body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  height: auto;
}

.ant-btn-primary {
  border-color: var(--primary);
  background: var(--primary);
  font-weight: bold;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  border-color: var(--primary);
  background: var(--primary);
}

.ant-btn-link {
  color: var(--primary);
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid var(--primary);
}

.ant-menu-submenu-selected, .ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: var(--primary);
  font-weight: 500;
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
  color: var(--primary);
}

.ant-menu-item a:hover {
  color: var(--primary);
}

h1.ant-typography {
  text-align: center;
  color: var(--primary);
}

.ant-modal-title {
  color: var(--primary);
  font-weight: 700 !important;
  text-align: center;
  font-size: 20px;
}



@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}
